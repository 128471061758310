var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.toggle.warehouseRentDetail
    ? _c(
        "div",
        { staticClass: "px-3 border-bottom" },
        [
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_document"),
                prop: "document",
                "label-col": _vm.labelCol
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("a-input-number", {
                staticStyle: { width: "170px" },
                attrs: {
                  value: _vm.form.document,
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  precision: _vm.DECIMAL_PLACES_QTY,
                  min: 0,
                  placeholder: _vm.$t("lbl_type_here"),
                  readonly: _vm.isUnbilled
                },
                on: {
                  change: function(e) {
                    return _vm.commitState(e, "document")
                  }
                }
              })
            ],
            1
          ),
          _c("div", [
            _c(
              "div",
              { staticClass: "d-flex align-items-center" },
              [
                _c(
                  "label",
                  {
                    staticClass:
                      "form-label ant-col-5 ant-col ant-form-item-label ant-form-item-label-left"
                  },
                  [_vm._v(_vm._s(_vm.$t("lbl_rush_handling")) + ":")]
                ),
                _c(
                  "a-form-model-item",
                  { staticClass: "mr-3", attrs: { prop: "grossWeight" } },
                  [
                    _c("a-input-number", {
                      staticStyle: { width: "170px" },
                      attrs: {
                        value: _vm.form.grossWeight,
                        formatter: _vm.formatterNumber,
                        parser: _vm.reverseFormatNumber,
                        precision: _vm.DECIMAL_PLACES_QTY,
                        min: 0,
                        placeholder: _vm.$t("lbl_type_here"),
                        readonly: ""
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  { staticClass: "mr-3", attrs: { prop: "rushHoldingValue" } },
                  [
                    _c("a-input-number", {
                      staticStyle: { width: "170px" },
                      attrs: {
                        value: _vm.form.rushHoldingValue,
                        formatter: _vm.formatterNumber,
                        parser: _vm.reverseFormatNumber,
                        precision: _vm.DECIMAL_PLACES_QTY,
                        min: 0,
                        placeholder: _vm.$t("lbl_type_here"),
                        readonly: _vm.isUnbilled
                      },
                      on: {
                        change: function(e) {
                          return _vm.commitState(e, "rushHoldingValue")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          prop: "rushHoldingTotal",
                          "label-col": _vm.labelCol,
                          colon: false
                        }
                      },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))]),
                        _c("span", { staticClass: "ml-3" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(_vm.form.rushHoldingTotal)
                            )
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("div", [
            _c(
              "div",
              { staticClass: "d-flex flex-wrap align-items-center" },
              [
                _c(
                  "label",
                  {
                    staticClass:
                      "form-label ant-col-5 ant-col ant-form-item-label ant-form-item-label-left"
                  },
                  [_vm._v(_vm._s(_vm.$t("lbl_storage_charge_day")) + ":")]
                ),
                _c(
                  "a-form-model-item",
                  { staticClass: "mr-3", attrs: { prop: "storageChargeDay" } },
                  [
                    _c("a-input-number", {
                      staticStyle: { width: "90px" },
                      attrs: {
                        value: _vm.form.storageChargeDay,
                        min: 0,
                        placeholder: _vm.$t("lbl_type_here"),
                        readonly: _vm.isUnbilled
                      },
                      on: {
                        change: function(e) {
                          return _vm.commitState(e, "storageChargeDay")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticClass: "mr-3",
                    attrs: { prop: "storageChargeValue" }
                  },
                  [
                    _c("a-input-number", {
                      staticStyle: { width: "135px" },
                      attrs: {
                        value: _vm.form.storageChargeValue,
                        formatter: _vm.formatterNumber,
                        parser: _vm.reverseFormatNumber,
                        precision: _vm.DECIMAL_PLACES_QTY,
                        min: 0,
                        placeholder: _vm.$t("lbl_type_here"),
                        readonly: _vm.isUnbilled
                      },
                      on: {
                        change: function(e) {
                          return _vm.commitState(e, "storageChargeValue")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticClass: "mr-3",
                    attrs: { prop: "storageChargeValue1" }
                  },
                  [
                    _c("a-input-number", {
                      staticStyle: { width: "135px" },
                      attrs: {
                        value: _vm.form.storageChargeValue1,
                        formatter: _vm.formatterNumber,
                        parser: _vm.reverseFormatNumber,
                        precision: _vm.DECIMAL_PLACES_QTY,
                        min: 0,
                        placeholder: _vm.$t("lbl_type_here"),
                        readonly: _vm.isUnbilled
                      },
                      on: {
                        change: function(e) {
                          return _vm.commitState(e, "storageChargeValue1")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticClass: "mr-3",
                    attrs: {
                      prop: "storageChargeTotal",
                      "label-col": _vm.labelCol,
                      colon: false
                    }
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))]),
                    _c("span", { staticClass: "ml-3" }, [
                      _vm._v(
                        _vm._s(_vm._f("currency")(_vm.form.storageChargeTotal))
                      )
                    ])
                  ]
                )
              ],
              1
            )
          ]),
          _c("div", [
            _c(
              "div",
              { staticClass: "d-flex flex-wrap align-items-center" },
              [
                _c(
                  "label",
                  {
                    staticClass:
                      "form-label ant-col-5 ant-col ant-form-item-label ant-form-item-label-left"
                  },
                  [_vm._v(_vm._s(_vm.$t("lbl_air_contribution")) + ":")]
                ),
                _c(
                  "a-form-model-item",
                  { staticClass: "mr-3", attrs: { prop: "grossWeight" } },
                  [
                    _c("a-input-number", {
                      staticStyle: { width: "170px" },
                      attrs: {
                        value: _vm.form.grossWeight,
                        formatter: _vm.formatterNumber,
                        parser: _vm.reverseFormatNumber,
                        precision: _vm.DECIMAL_PLACES_QTY,
                        min: 0,
                        placeholder: _vm.$t("lbl_type_here"),
                        readonly: ""
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticClass: "mr-3",
                    attrs: { prop: "airContributionValue" }
                  },
                  [
                    _c("a-input-number", {
                      staticStyle: { width: "170px" },
                      attrs: {
                        value: _vm.form.airContributionValue,
                        formatter: _vm.formatterNumber,
                        parser: _vm.reverseFormatNumber,
                        precision: _vm.DECIMAL_PLACES_QTY,
                        min: 0,
                        placeholder: _vm.$t("lbl_type_here"),
                        readonly: _vm.isUnbilled
                      },
                      on: {
                        change: function(e) {
                          return _vm.commitState(e, "airContributionValue")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  {
                    attrs: {
                      prop: "airContributionTotal",
                      "label-col": _vm.labelCol,
                      colon: false
                    }
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))]),
                    _c("span", { staticClass: "ml-3" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(_vm.form.airContributionTotal)
                        )
                      )
                    ])
                  ]
                )
              ],
              1
            )
          ]),
          _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-wrap align-items-center justify-content-start"
              },
              [
                _c(
                  "label",
                  {
                    staticClass:
                      "form-label ant-col-5 ant-col ant-form-item-label ant-form-item-label-left"
                  },
                  [_vm._v(_vm._s(_vm.$t("lbl_kade_charge")) + ":")]
                ),
                _c(
                  "a-form-model-item",
                  { staticClass: "mr-3", attrs: { prop: "grossWeight" } },
                  [
                    _c("a-input-number", {
                      staticStyle: { width: "170px" },
                      attrs: {
                        value: _vm.form.grossWeight,
                        formatter: _vm.formatterNumber,
                        parser: _vm.reverseFormatNumber,
                        precision: _vm.DECIMAL_PLACES_QTY,
                        min: 0,
                        placeholder: _vm.$t("lbl_type_here"),
                        readonly: ""
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  { staticClass: "mr-3", attrs: { prop: "kadeChargeValue" } },
                  [
                    _c("a-input-number", {
                      staticStyle: { width: "170px" },
                      attrs: {
                        value: _vm.form.kadeChargeValue,
                        formatter: _vm.formatterNumber,
                        parser: _vm.reverseFormatNumber,
                        precision: _vm.DECIMAL_PLACES_QTY,
                        min: 0,
                        placeholder: _vm.$t("lbl_type_here"),
                        readonly: _vm.isUnbilled
                      },
                      on: {
                        change: function(e) {
                          return _vm.commitState(e, "kadeChargeValue")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticClass: "mr-3",
                    attrs: {
                      prop: "kadeChargeTotal",
                      "label-col": _vm.labelCol,
                      colon: false
                    }
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))]),
                    _c("span", { staticClass: "ml-3" }, [
                      _vm._v(
                        _vm._s(_vm._f("currency")(_vm.form.kadeChargeTotal))
                      )
                    ])
                  ]
                )
              ],
              1
            )
          ]),
          _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-wrap align-items-center justify-content-start"
              },
              [
                _c(
                  "label",
                  {
                    staticClass:
                      "form-label ant-col-5 ant-col ant-form-item-label ant-form-item-label-left"
                  },
                  [_vm._v(_vm._s(_vm.$t("lbl_pjkp2u")) + ":")]
                ),
                _c(
                  "a-form-model-item",
                  { staticClass: "mr-3", attrs: { prop: "grossWeight" } },
                  [
                    _c("a-input-number", {
                      staticStyle: { width: "170px" },
                      attrs: {
                        value: _vm.form.grossWeight,
                        formatter: _vm.formatterNumber,
                        parser: _vm.reverseFormatNumber,
                        precision: _vm.DECIMAL_PLACES_QTY,
                        min: 0,
                        placeholder: _vm.$t("lbl_type_here"),
                        readonly: ""
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  { staticClass: "mr-3", attrs: { prop: "pjkp2uValue" } },
                  [
                    _c("a-input-number", {
                      staticStyle: { width: "170px" },
                      attrs: {
                        value: _vm.form.pjkp2uValue,
                        formatter: _vm.formatterNumber,
                        parser: _vm.reverseFormatNumber,
                        precision: _vm.DECIMAL_PLACES_QTY,
                        min: 0,
                        placeholder: _vm.$t("lbl_type_here"),
                        readonly: _vm.isUnbilled
                      },
                      on: {
                        change: function(e) {
                          return _vm.commitState(e, "pjkp2uValue")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticClass: "mr-3",
                    attrs: {
                      prop: "pjkp2uTotal",
                      "label-col": _vm.labelCol,
                      colon: false
                    }
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))]),
                    _c("span", { staticClass: "ml-3" }, [
                      _vm._v(_vm._s(_vm._f("currency")(_vm.form.pjkp2uTotal)))
                    ])
                  ]
                )
              ],
              1
            )
          ]),
          _c("div", [
            _c(
              "div",
              { staticClass: "d-flex flex-wrap align-items-center" },
              [
                _c(
                  "label",
                  {
                    staticClass:
                      "form-label ant-col-5 ant-col ant-form-item-label ant-form-item-label-left"
                  },
                  [_vm._v(_vm._s(_vm.$t("lbl_tax")) + ":")]
                ),
                _c(
                  "a-form-model-item",
                  { staticClass: "mr-3", attrs: { prop: "taxWarehouseValue" } },
                  [
                    _c("span", { staticClass: "ml-3" }, [
                      _vm._v(
                        _vm._s(_vm._f("currency")(_vm.form.taxWarehouseValue))
                      )
                    ])
                  ]
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticClass: "mr-3",
                    attrs: { prop: "taxWarehousePercent" }
                  },
                  [
                    _c("a-input-number", {
                      staticStyle: { width: "170px" },
                      attrs: {
                        value: _vm.form.taxWarehousePercent,
                        formatter: _vm.formatterPercent,
                        precision: _vm.DECIMAL_PLACES_QTY,
                        min: 0,
                        max: 100,
                        placeholder: _vm.$t("lbl_type_here"),
                        readonly: _vm.isUnbilled
                      },
                      on: {
                        change: function(e) {
                          return _vm.commitState(e, "taxWarehousePercent")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticClass: "mr-3",
                    attrs: {
                      prop: "taxWarehouseTotal",
                      "label-col": _vm.labelCol,
                      colon: false
                    }
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))]),
                    _c("span", { staticClass: "ml-3" }, [
                      _vm._v(
                        _vm._s(_vm._f("currency")(_vm.form.taxWarehouseTotal))
                      )
                    ])
                  ]
                )
              ],
              1
            )
          ]),
          _c(
            "a-form-model-item",
            {
              staticClass: "mr-3",
              attrs: {
                label: _vm.$t("lbl_others"),
                prop: "otherWarehouse",
                "label-col": _vm.labelCol
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("a-input-number", {
                staticStyle: { width: "170px" },
                attrs: {
                  value: _vm.form.otherWarehouse,
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  precision: _vm.DECIMAL_PLACES_QTY,
                  min: 0,
                  placeholder: _vm.$t("lbl_type_here"),
                  readonly: _vm.isUnbilled
                },
                on: {
                  change: function(e) {
                    return _vm.commitState(e, "otherWarehouse")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticClass: "mr-3",
              attrs: {
                label: _vm.$t("lbl_stamp_duty_etc"),
                prop: "stampDutyWarehouse",
                "label-col": _vm.labelCol
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("a-input-number", {
                staticStyle: { width: "170px" },
                attrs: {
                  value: _vm.form.stampDutyWarehouse,
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  precision: _vm.DECIMAL_PLACES_QTY,
                  min: 0,
                  placeholder: _vm.$t("lbl_type_here"),
                  readonly: _vm.isUnbilled
                },
                on: {
                  change: function(e) {
                    return _vm.commitState(e, "stampDutyWarehouse")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticClass: "mr-3",
              attrs: {
                label: _vm.$t("lbl_total_warehouse_rent"),
                prop: "totalWarehouseRent",
                "label-col": _vm.labelCol
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("span", { staticClass: "ml-3" }, [
                _vm._v(_vm._s(_vm._f("currency")(_vm.form.totalWarehouseRent)))
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }